import firebase from 'firebase/app'
import store from '@/store'

export default async function updateData(payload) {
  const { collection, d } = payload
  try {
    const uid = store.getters['userBasic/uid']

    if (collection === 'user') {
      await firebase.firestore().collection(collection).doc(uid).set(d, { merge: true })
    } else {
      let data = {}
      await firebase.firestore().collection(collection)
        .where('uid', '==', uid)
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              data = doc.data()
            })
          }
        })
      await firebase.firestore().collection(collection).doc(data.docID).set(d, { merge: true })
      // window.console.log('🐶', data.docID, collection)
    }

    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
